import { Controller } from "@hotwired/stimulus";
import Dashboard from "@uppy/dashboard";
import { uppyInstance, uploadedFileData } from "../uppy";
import { nanoid } from "nanoid";

export default class extends Controller {
  static targets = ["input", "destroyField"];
  static values = {
    types: Array,
    server: String,
    buttonText: String,
    maxFileSize: { type: Number, default: 5000000 },
    maxTotalFileSize: { type: Number, default: 50000000 },
  };

  connect() {
    this.uppy = this.createUppy();
  }
  disconnect() {
    this.uppy.close();
  }

  removeExistingFile(event) {
    event.preventDefault();

    const triggerElement = event.currentTarget;

    const attachmentId = triggerElement.dataset.attachmentId;
    console.log("ATTACHMENT ::::: ", triggerElement.parentElement);
    const hiddenField = this.destroyFieldTargets.find((field) => field.dataset.attachmentId === attachmentId);
    console.log("HIDDEN FIELD:::::: ", hiddenField);
    if (hiddenField) {
      hiddenField.value = "1";
      const attachmentElement = triggerElement.closest(".attachment");
      attachmentElement.classList.add("hidden");
    }
  }

  createUppy() {
    const uppy = uppyInstance({
      id: this.inputTarget.id,
      types: this.typesValue,
      server: this.serverValue,
    }).use(Dashboard, {
      target: this.inputTarget.parentNode,
      inline: true,
      height: 300,
      width: "20rem",
      replaceTargetContent: true,
      proudlyDisplayPoweredByUppy: false,
      showRemoveButtonAfterComplete: true,
      singleFileFullScreen: true,
    });

    // uppy.on("*", (event, data) => {
    //   console.log(`Event: ${String(event)}`, data);
    // });

    uppy.on("upload-success", (file, response) => {
      const hiddenField = document.createElement("input");
      hiddenField.type = "hidden";
      hiddenField.name = `project_update[attachments_attributes][${nanoid()}][attachment]`;
      hiddenField.value = uploadedFileData(file, response, this.serverValue);
      this.element.appendChild(hiddenField);
    });

    uppy.on("file-removed", (file, reason) => {
      // Extract the file name from the event
      const fileName = file.name;
      console.log("file-removed event triggered....", file, fileName);
      // Get the Uppy root element
      const uppyRoot = document.querySelector(".uppy-Root");
      if (!uppyRoot) {
        console.error("Uppy root element not found!");
        return;
      }

      // Find the hidden input element within the Uppy root
      console.log("HIDDEN INPUTS:::::: ", this.element.querySelectorAll('input[type="hidden"]'));
      const hiddenInput = Array.from(this.element.querySelectorAll('input[type="hidden"]')).find((input) => {
        try {
          console.log("INPUT::::: ", input);
          const value = JSON.parse(input.value);
          console.log("PARSED INPUT VALUE:::::: ", value);
          return value.metadata.filename === fileName;
        } catch (e) {
          console.warn("Skipping invalid input value:", input.value);
          return false;
        }
      });

      if (hiddenInput) {
        // Remove the element from the DOM
        hiddenInput.remove();
        console.log(`Removed hidden input for file: ${fileName}`);
      } else {
        console.warn(`No hidden input found for file: ${fileName}`);
      }
    });

    uppy.on("upload-error", function (file, error) {
      console.log("UPLOAD ERROR:::: ", error);
    });
    return uppy;
  }
}
