import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["firstLabel", "secondLabel"];

  static classes = ["active", "inactive"];

  connect() {
    console.log("TOGGLE RADIO CONTROLLER CONNECTED::::::: ", this.firstLabelTarget, this.secondLabelTarget);
    console.log("ACTIVE CLASS::::::: ", this.activeClasses, " INACTIVE CLASS::::: ", this.inactiveClasses);
  }

  updateStyles() {
    // Safely get elements
    const labels = [this.firstLabelTarget, this.secondLabelTarget];
    const firstInput = this.element.querySelector('input[value="' + this.firstValue + '"]');

    // Guard clause if inputs are not found
    if (!firstInput || labels.length !== 2) return;

    const isFirstChecked = firstInput.checked;

    labels.forEach((label, index) => {
      // Ensure label exists before manipulating
      if (!label) return;

      if (index === 0 && isFirstChecked) {
        // First input checked
        label.classList.add(...this.activeClasses);
        label.classList.remove(...this.inactiveClasses);
      } else if (index === 0 && !isFirstChecked) {
        // First input not checked
        label.classList.remove(...this.activeClasses);
        label.classList.add(...this.inactiveClasses);
      } else if (index === 1 && !isFirstChecked) {
        // Second input checked
        label.classList.add(...this.activeClasses);
        label.classList.remove(...this.inactiveClasses);
      } else {
        // Second input not checked
        label.classList.remove(...this.activeClasses);
        label.classList.add(...this.inactiveClasses);
      }
    });
  }

  toggle(event) {
    // Trigger updateStyles when an input is clicked
    this.updateStyles();
  }

  // Getters for first and second values with fallback
  get firstValue() {
    return this.element.dataset.firstValue || "first";
  }

  get secondValue() {
    return this.element.dataset.secondValue || "second";
  }
}
